body,html{

}

.menu-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}

.menu-content {
  padding: 24px 0;
  background-color: rgba(0, 0, 0, 0.8);
}

.menu-item {
  color: #ffffff;
  text-align: center;
  margin: 0 90px;
  padding-top: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(204, 204, 204, 1);
}

.menu-text-en {
  font-size: 10px;
  font-family: Didot, serif;
}

.menu-text-zh {
  margin-top: 6px;
  font-size: 16px;
  font-weight: bold;
}

.main-header{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100px;
  width: 100%;
  background-color: #000;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;
}

.main-header .main-logo{
}

.main-header .main-left{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.main-header .main-info{
  display: flex;
  margin-left: 5px;
  flex-direction: column;
}

.main-header .main-value{
  font-size: 16px;
  color: rgba(255, 255, 255, 1);
  font-weight: 300;
}

.main-header .main-text{
  display: flex;
  flex-direction: row;
}

.main-header .main-title{
  font-size: 24px;
  font-weight: 700;
  color: rgba(255, 255, 255, 1);
}

.main-header .main-desc{
  font-size: 24px;
  margin-left: 5px;
  color: rgba(255, 255, 255, 1);
}

.main-header .main-right{
  position: absolute;
  top: 0;
  right: 20px;
  bottom: 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.main-header .main-line{
  margin: 3px 0;
  width: 23px;
  height: 3px;
  background: rgba(196, 196, 196, 1);
}

.main-bg{
  z-index: 1;
  top: 100px;
  position: absolute;
  width: 100%;
}

.main-bg .main-bg-icon{
  width: 100%;
}

.main-bg .main-bg-style{
  position: absolute;
  height: 100%;
  top:0;
  left:0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.main-bg .main-box{
  position: absolute;
  display: flex;
  top: 30%;
  flex-direction: column;
  width: 100%;
}

.main-bg .main-title{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.main-bg .main-desc{
  margin-top: 8px;
  font-size: 20px;
  line-height: 20px;
  color: #fff;
  display: flex;
  font-weight: 300;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.main-bg .main-title-1{
  font-size: 36px;
  line-height: 36px;
  font-weight: 700;
  color: #fff;
}

.main-bg .main-title-2{
  line-height: 36px;
  font-size: 36px;
  color: #fff;
  margin-left: 30px;
}

.main-bg  .main-panel{
  position: absolute;
  display: flex;
  top: 60%;
  flex-direction: column;
  width: 100%;
}

.main-bg .main-item{
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  width: 100%;
  align-items: center;
  box-sizing: border-box;
}

.main-bg .main-item:first-child{
  margin-top: 0;
}

.main-bg .main-font-1{
  font-size: 10px;
  font-weight: 400;
  line-height: 10px;
  color: #fff;
  font-family: Didot, serif;
}

.main-bg .main-font-2{
  margin-top: 10px;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  color: #fff;
}

.main-bg .main-line{
  margin: 10px;
  height: 1px;
  width: 100%;
  background-color: rgba(204, 204, 204, 1);
}

.main-list{
  position: absolute;
  top: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 1;
  background-color: #000;
}

.main-list .main-txt{
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 30px;
  width: 100%;
  text-align: center;
  margin-top: 30px;
  color: rgba(255, 255, 255, 1);
}

.main-list .main-item{
  width: 100%;
  position: relative;
  margin-top: 7px;
}

.main-list .main-item:first-child{
  margin-top: 0;
}

.main-list .main-image-style{
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.main-list .main-title{
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
  justify-content: center;
  align-items: center;
  height: 250px;
  background-color: rgba(0, 0, 0, 0.5);
}

.main-list .main-title-1{
  font-size: 18px;
  line-height: 18px;
  font-weight: 600;
  color: #fff;
}

.main-list .main-title-2{
  margin-top: 14px;
  font-size: 14px;
  line-height: 14px;
  font-weight: 200;
  color: #fff;
}

.contact-page{
  position: relative;
}

.contact-page .contact-title{
    padding-top: 20px;
    padding-left: 50px;
    width: 100%;
    padding-bottom: 20px;
    box-sizing: border-box;
    background-color: #000;
    display: flex;
    flex-direction: column;
}

.contact-page .contact-title-1{
  font-size: 20px;
  font-weight: 200;
  color: #fff;
  letter-spacing: 4px;
}

.contact-page .contact-title-2{
  font-size: 20px;
  letter-spacing: 4px;
  font-weight: 600;
  color: #fff;
  margin-left: 30px;
}

.contact-page .contact-title-3{
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  letter-spacing: 4px;
  margin-left: 80px;
}

.contact-page .contact-item{
  margin-top: 7px;
  width: 100%;
  position: relative;
}

.contact-page .contact-image-style{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.contact-page .contact-desc{
  padding: 15px;
}

.contact-page .contact-desc-1{
  position: relative;
  font-size: 16px;
  line-height: 20px;
  font-weight: 200;
  color: #fff;
  z-index: 1;
}

.contact-page .contact-desc-2{
  position: relative;
  font-size: 16px;
  margin-top: 5px;
  line-height: 20px;
  font-weight: 600;
  color: #fff;
  z-index: 1;
}

.contact-page .contact-text{
  padding: 15px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
}

.contact-page .contact-txt{
  font-size: 20px;
  font-weight: 200;
  letter-spacing: 4px;
  line-height: 34px;
  width: 100%;
  text-align: center;
  margin-top: 20px;
  color: rgba(255, 255, 255, 1);
}

.contact-page .contact-ways {
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-page .contact-ways .contact-way {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 28px;
  font-weight: lighter;
  color: #ffffff;
  font-size: 13px;
  text-decoration: none;
}

.contact-page .contact-ways .contact-way .contact-way-icon {
  width: 32px;
  height: 22px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-bottom: 12px;
}

.contact-page .contact-tips {
  padding: 36px 0;
  color: #ffffff;
  text-align: center;
  font-size: 14px;
}
